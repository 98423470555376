SUPERVISION_SESSIONS = {
	init: function() {
		$('input[type=radio][name="supervision_session_participant[participation]"]').change(function(){
			
			console.log($(this).val())
			if ($(this).val() === 'true') {
				console.log("Hide!")
				$('#reason_for_not_attending').hide()
			}else{
				console.log("Reveal!")
				$('#reason_for_not_attending').show()
			}
		})

		$('#supervision_session_supervision_session_group_id').change(function(e) {
			var supervisionSessionGroupId = $('#supervision_session_supervision_session_group_id').val();
			console.log("Supervision Session Group ID: " + supervisionSessionGroupId)

			console.log("TRUE")
			$.ajax({
				url: '/employee_details',
				type: 'GET',
				dataType: 'json',
				/*data: { per_page: 90, location_id: locateableId, scope: $('#supervision_session_provider_dimension_ids').data("scope") },*/
				data: { per_page: 100, group_id: supervisionSessionGroupId, scope: ["elegible_for_session_through_group"]},
				
				success: function(d) {
					// loop through d.items and add via select2 api as selected options
					const participantSelect = $('#supervision_session_employee_dimension_ids')
					$("#supervision_session_employee_dimension_ids option").remove();

					for (const employee of d.items) { 
						if (participantSelect.find("option[value='" + employee.id + "']").length) {
								const newValues = participantSelect.val().push(employee.id)
							participantSelect.val(newValues).trigger('change')
						} else { 
							// Create a DOM Option and pre-select by default
							var newOption = new Option(employee.full_name, employee.id, true, true);
							newOption.title = employee.full_name
							$(newOption).data('title',employee.full_name)
							// Append it to the select
							console.log("name/id: " + employee.full_name + " " + employee.id)
							participantSelect.append(newOption).trigger('change')
						} 
					}
				},
				error: function(d){
					console.error('uh oh')
					console.error(d)
				}
			});

		})
	}
}

$(document).on('turbolinks:load', SUPERVISION_SESSIONS.init)
